.subtable {
  width: 100%;
  height: 100%;
  overflow: hidden;
}
.detailTable {
  & :global(.ant-table-body),
  & :global(.ant-card-body) {
    max-height: 100% !important;
    overflow: hidden !important;
  }
  & tr {
    background-color: #ecebeb;
  }
  & :global(.ant-table.ant-table-middle) {
    margin: 0px !important;
    overflow: hidden;
  }
}
