.attributeLabel {
  font-weight: bold;
  font-size: 60%;
}

.attributeWrapper {
  border: 1px solid #ccc;
  padding: 0px 3px;
  margin: 2px;
  border-radius: 5px;
}
