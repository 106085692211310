.wrapper {
  position: relative;
  margin: 16px 0 0 0;
  & :global(.ant-select) {
    width: 100%;
  }
}

.input {
  & :global(.ant-select-selector) {
    box-shadow: 0 1px 3px 0 var(--color-box-shadow);
    border-radius: 6px !important;
    font-size: 14px;
    margin: 4px 0px;
  }
}

.selectError :global(.ant-select-selector),
.selectError :global(.ant-select-selector):hover,
.selectError :global(.ant-select-selector):focus {
  border: 1px solid var(--color-warning) !important;
  box-shadow: none;
}

.label {
  font-size: 13px;
  font-weight: 600;
  line-height: 1.05;
  color: var(--color-near-black);
  margin: 0;
}

.error {
  position: absolute;
  font-size: 10px;
  color: var(--color-warning);
  bottom: -25px;
  left: 12px;
}

.viewOnly:global(.ant-select-selector[disabled]) {
  background-color: var(--color-white);
  cursor: default;
  color: var(--color-near-black);
}

.loadMore {
  width: 100%;
  display: flex;
  padding: 8px;
  justify-content: center;
  cursor: pointer;
}
