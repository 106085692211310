.modalHeader {
  display: flex;
  align-items: center;
  padding: 4px;
  svg {
    fill: var(--color-yellow);
    height: 20px;
    width: 20px;
    transform: rotate(180deg);
  }
  h1 {
    margin: 0;
    margin-left: 8px;
    font-size: 16px;
    font-weight: 600;
  }
}

.popoverStyling:global(.ant-popover) {
  :global(.ant-popover-content) {
    :global(.ant-popover-inner-content) {
      padding: 0;
    }
  }
}

.itemContainer {
  max-height: 248px;
  width: 100%;
  display: inline-block;
  overflow-y: auto;
}
