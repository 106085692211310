.detailsWrapper {
  padding: 0px;
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: hidden;
}

.featuresSectionWrapper {
  flex: 1 1 auto;
  padding: 16px;
  overflow: auto;
}

.primaryFeaturesWrapper {
  display: flex;
  flex-wrap: wrap;
  > div {
    margin: 2px 25px;
    margin-left: 0px;
  }
}

.secondaryFeaturesWrapper {
  display: flex;
  flex-wrap: wrap;
  > div {
    margin: 2px 30px;
    margin-left: 0px;
  }
}

.title {
  color: var(---color-dark-gray);
  font-size: 14px;
  line-height: 16px;
  font-weight: 600;
}

.detail {
  color: var(--color-reading-black);
  font-size: 12px;
  font-weight: 600;
  text-transform: uppercase;
  margin-bottom: 16px;
}

.subtitle {
  color: var(--color-dark-gray);
  font-size: 10px;
  line-height: 13px;
}

.description {
  color: var(--color-reading-black);
  font-size: 12px;
  font-weight: 400;
  line-height: 10px;
}

.footerWrapper {
  padding: 8px 8px 16px 8px;
  background-color: var(--color-white);
  border-top: 1px solid var(--color-background-light);
}

.detailsButton:global(.ant-btn) {
  background-color: transparent;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin: 0px 8px;
  span,
  svg {
    height: 20px;
    width: 20px;
  }
  p {
    margin-bottom: -1px;
    margin-left: 8px;
    margin-right: 8px;
  }
  font-size: 12px;
}

.footerTitle {
  margin-left: 8px;
  font-size: 10px;
  color: var(--color-dark-gray);
}

.footerButtonWrapper {
  display: flex;
  width: 100%;
  justify-content: space-between;
}

.blueButton:global(.ant-btn) {
  color: var(--color-light-blue);
  border-color: var(--color-light-blue);
}

.greenButton:global(.ant-btn) {
  color: var(--color-green);
  border-color: var(--color-green);
}

.spaceBefore {
  margin-top: 15px;
}
