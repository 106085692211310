.wrapper {
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  background-color: transparent;
  border-radius: 4px;
  margin: 8px;
  overflow: hidden;
  box-shadow: 2px 1px 5px 0 var(--color-box-shadow);
}

.thinCard {
  min-width: 246px;
  max-width: 246px;
}

.wideCard {
  min-width: 263px;
  max-width: 263px;
}

.header {
  position: relative;
  padding: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--color-full-black);
  text-transform: capitalize;
  color: var(--color-white);
  font-weight: 600;
  p {
    margin: 0;
    word-break: break-word;
  }
}

.search {
  background-color: var(--color-white);
  padding: 8px;
  :global(.ant-input-affix-wrapper) {
    border-radius: 2px !important;
    height: 32px;
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    :global(.ant-input-prefix) {
      margin: 0;
    }
    :global(.ant-input::placeholder) {
      letter-spacing: 1px;
      font-size: 12px;
    }
  }
}

.newItemButton {
  > :disabled {
    cursor: not-allowed;
    svg {
      fill: var(--color-white-with-opacity);
    }
  }
  position: absolute;
  right: 4px;
  top: 13px;
  margin: 0 8px;
  height: 20px;
  width: 20px;
  button {
    background-color: transparent;
    border: 0;
    padding: 0;
    height: 100%;
    width: 100%;
    cursor: pointer;
    span,
    svg {
      height: 100%;
      width: 100%;
      fill: var(--color-white);
    }
  }
}

.itemList {
  flex: 1 1 auto;
  overflow: auto;
  scrollbar-width: thin;
}

.loadMore {
  padding: 12px 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--color-background-light);
  border-top: 1px solid var(--color-light-gray);
  > :global(.ant-btn) {
    border-color: var(--color-light-blue);
    color: var(--color-light-blue);
    background-color: transparent;
    width: 100%;
  }
}
