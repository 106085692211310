.wrapper {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 8px 16px;
}

.title {
  color: var(--color-reading-black);
  font-weight: 600;
  font-size: 12px;
  margin: 0;
  flex: 1 1 auto;
}

.buttonSection {
  display: flex;
  button {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 12px;
  }
}
