.inputTitle {
  margin: 0;
  font-weight: 600;
  color: var(--color-near-black);
}
.inputWrapper {
  margin-bottom: 8px;
}
.modalTitle {
  color: var(--color-dark-blue);
}
.select {
  width: 100% !important;
  margin-top: 0;
  & :global(.ant-select-selector) {
    margin: 0px;
  }
}
.multiselect {
  width: 100%;
}
.cleanBtn {
  position: absolute;
  bottom: 11px;
}
