.wrapper {
  position: relative;
  margin: 16px 0 0 0;
}

.input:global(.ant-input) {
  box-shadow: 0 1px 3px 0 var(--color-box-shadow);
  border-radius: 6px;
  font-size: 14px;
  margin: 4px 0px;
  resize: none;
}

.inputError:global(.ant-input),
.inputError:global(.ant-input):hover,
.inputError:global(.ant-input):focus {
  border: 1px solid var(--color-warning);
  box-shadow: none;
}

.label {
  font-size: 12px;
  font-weight: 600;
  line-height: 1.05;
  color: var(--color-near-black);
  margin: 0;
}

.error {
  position: absolute;
  font-size: 10px;
  color: var(--color-warning);
  bottom: -25px;
  left: 12px;
}

.counter {
  position: absolute;
  right: 5px;
  bottom: -14px;
  font-weight: 600;
  color: var(--color-near-black);
  margin: 0;
  font-size: 10px;
}

.viewOnly:global(.ant-input[disabled]) {
  background-color: var(--color-white);
  cursor: default;
  color: var(--color-near-black);
}
