@import "~antd/dist/antd.css";

// Variables
:root {
  --color-background-dark: #eaeaea;
  --color-background-light: #f9fafc;
  --color-background-disabled: #f5f5f5;
  --color-dark-blue: #32325d;
  --color-light-blue: #619cf8;
  --color-light-blue-with-opacity: rgba(97, 156, 248, 0.16);
  --color-white: #fff;
  --color-near-white: #f4f5f7;
  --color-mid-white: #f8f9fb;
  --color-lighter-gray: hsla(0, 0%, 92%, 1);
  --color-light-gray: #d8d8e0;
  --color-mid-gray: #d6d6d6;
  --color-dark-gray: #8898aa;
  --color-near-black: #525f7f;
  --color-reading-black: #323232;
  --color-full-black: #000;
  --color-warning: #ff0056;
  --color-light-green: #55c51d;
  --color-light-green-with-opacity: rgba(82, 196, 26, 0.08);
  --color-green: #27ae60;
  --color-black-with-opacity: rgba(0, 0, 0, 0.65);
  --color-gray-with-opacity: rgba(136, 152, 170, 0.16);
  --color-white-with-opacity: rgba(255, 255, 255, 0.24);
  --color-box-shadow: rgba(50, 50, 93, 0.15);
  --transition-regular: all 0.25s ease-in-out;
  --color-orange: #ff6a14;
  --color-yellow: #ffce00;

  --color-primary: #32325d;
  --color-secondary: #619cf8;
}

//Globals
body {
  font-size: 48px;
  font-family: "OpenSans", Arial, Helvetica, sans-serif;
  background-color: var(--color-background-light);
}

#root {
  height: 100%;
  width: 100%;
}

// Generic PIX classes

.pix-empty-state {
  margin-top: 42px;
}
