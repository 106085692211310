.tableHeader {
  font-weight: bold;
  color: var(--color-secondary);
  background-color: var(--color-light-gray);
}
.addNewButton {
  margin-right: 10px;
  margin-bottom: 15px;
  margin-top: 15px;
}
