.wrapper {
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: hidden;
}
.header {
  padding: 8px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid var(--color-light-gray);
}
.userNavigation {
  flex: 1 1 auto;
  display: flex;
  align-items: center;
  :global(.ant-select) {
    border-radius: 2px !important;
    margin-top: -10px;
    margin-bottom: 10px;
    height: 32px;
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    :global(.ant-input-prefix) {
      margin: 0;
    }
    :global(.ant-input::placeholder) {
      letter-spacing: 1px;
      font-size: 12px;
    }
  }
}
.breadcrumb:global(.ant-breadcrumb) {
  margin-left: 12px;
  flex: 1 1 auto;
}
.expandIcon {
  margin: 0 8px;
  height: 32px;
  width: 32px;
  button {
    background-color: transparent;
    border: 0;
    padding: 0;
    height: 100%;
    width: 100%;
    cursor: pointer;
    span,
    svg {
      height: 100%;
      width: 100%;
    }
  }
}
.globalSearch {
  width: 33%;
}
