.wrapper:global(.ant-modal) {
  display: flex;
  font-size: 12px;
  color: var(--color-dark-blue);
  p {
    margin: 4px 0;
  }
  :global(.ant-modal-header) {
    div {
      color: var(--color-dark-blue);
      font-weight: 600;
      font-size: 16px;
    }
  }
  :global(.ant-modal-body) {
    padding: 0;
    width: 600px;
  }
}

.warning {
  display: flex;
  align-items: center;
  padding: 8px;
  padding-left: 24px;
  background-color: var(--color-mid-white);
  font-size: 12px;
  color: var(--color-dark-blue);
  p > span {
    font-weight: 600;
    color: var(--color-light-blue);
  }
}

.icon {
  margin-right: 8px;
  color: var(--color-light-blue);
  width: 20px;
  height: 20px;
  span,
  svg {
    height: 100%;
    width: 100%;
  }
}

.selector {
  padding: 0 16px 16px 16px;
}

.heading {
  color: var(--color-dark-blue);
  font-size: 12px;
  margin-bottom: -7px;
  font-weight: 500;
}
