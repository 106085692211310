.footer {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  right: 50px;
}

.footerFilter:global(.ant-btn) {
  color: var(--color-light-blue);
  border: 1px solid var(--color-light-blue);
  margin-right: 16px;
}

.footerCreate:global(.ant-btn-primary) {
  color: var(--color-white);
  background-color: var(--color-light-blue);
  font-weight: 600;
}
