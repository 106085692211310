.transactionCard {
  height: 100%;
  width: 100%;
  & > :global(.ant-card-body) {
    height: calc(100% - 92px);
    padding: 0px;
  }
  & :global(.ant-table-wrapper) {
    & :global(.ant-spin-nested-loading),
    & :global(.ant-spin-container),
    & :global(.ant-table),
    & :global(.ant-table-container) {
      height: 100%;
    }
  }
  & :global(.ant-card-head-wrapper) {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
  }
  & :global(.ant-card-head-title) {
    border-bottom: 2px solid var(--color-light-gray);
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    font-size: 12px;
    color: var(--color-reading-black);
    font-weight: 600;
    margin: 0;
    p {
      margin: 0;
      margin-left: 12px;
    }
    div {
      margin-right: 12px;
    }
  }
  & :global(.ant-card-head-title),
  & :global(.ant-card-extra) {
    margin: 0;
    width: 100%;
    float: none;
  }
  & :global(.ant-card-head) {
    padding: 0;
    min-height: 40px !important;
    border-bottom: 0px !important;
  }
  & :global(.pix-search-by-term) {
    padding: 2px 18px !important;
  }
  & :global(td.ant-table-cell) {
    padding: 0px !important;
  }
  .headingButtonSection {
    :global(.ant-btn) {
      color: var(--color-light-blue);
      border: 1px solid var(--color-light-blue);
    }
  }
  .infoButton:global(.ant-btn) {
    margin-left: 5px;
    border: 0;
    background-color: var(--color-light-blue-with-opacity);
    &:hover {
      background-color: var(--color-light-blue);
      color: var(--color-white);
    }
  }
  .associateButton:global(.ant-btn-primary[disabled]) {
    margin-left: 5px;
    border: 0;
    font-weight: 600;
  }
  .associateButton:global(.ant-btn) {
    margin-left: 5px;
    color: var(--color-white);
    font-weight: 600;
    padding: 5px 15px;
  }
  .tagTotalItems {
    background-color: rgb(50, 50, 93);
    color: white;
    width: 40px;
    height: 20px;
    border-radius: 10px;
    font-weight: bold;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .naFont {
    color: darkgray;
  }
  .sublistItem {
    padding: 4px 0px 0px 136px !important;
    display: inline-block;
    .sublistItemInfo {
      margin-bottom: 4px !important;
    }
  }
  .subtable {
    width: 100%;
    height: 100%;
    overflow: hidden;
  }
}
.gtincode {
  cursor: pointer;
  &:hover {
    color: cornflowerblue;
    text-decoration: underline;
  }
}
.dataType {
  margin: 10px 10px;
  padding: 8px;
  background-color: var(--color-light-blue);
  border: 1px solid var(--color-light-blue);
  color: white;
  cursor: pointer;
  display: inline;
}
