.heading {
  color: var(--color-black-with-opacity);
  font-size: 12px;
  font-weight: 800;
  padding: 8px 12px 0px 14px;
  margin: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  button,
  button:active,
  button:visited {
    background-color: var(--color-light-blue-with-opacity) !important;
    border-radius: 2px;
    border: none;
    color: var(--color-light-blue) !important;
  }
  button:hover {
    background-color: var(--color-light-blue) !important;
    color: white !important;
  }
}
.cardWrapper {
  background-color: var(--color-background-light);
  overflow: auto;
  scrollbar-width: thin;
}
.filterWrapper {
  display: flex;
  justify-content: space-between;
  padding: 8px 12px;
  .searchInput {
    width: 100%;
    padding-right: 8px;
  }
}

.createProductDrawer {
  & :global(.ant-drawer-title) {
    color: var(--color-dark-blue);
    font-size: 14px;
    font-weight: bold;
    padding-left: 16px;
  }
  & :global(.pix-default-content) {
    height: calc(100vh - 56px);
  }
}
.addButtons {
  & button:first-child {
    margin-left: 0px;
  }
  & button {
    margin-left: 4px;
    padding: 2px 8px;
    height: 24px;
  }
}
