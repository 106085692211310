.wrapper {
  width: 100%;
  margin: 0px;
  padding: 0px;
  height: calc(100% - 40px);
  overflow: auto;
  & form {
    padding: 0px 16px;
  }
}

.secondaryButton:global(.ant-btn-link) {
  font-size: 12px;
  float: right;
  padding: 0;
}

.loadingData {
  display: flex;
  height: 500px;
  align-items: center;
  justify-content: center;
}

.created input[disabled] {
  background-color: var(--color-background-disabled) !important;
}

.heading {
  margin: 22px 0 0 0;
  display: flex;
  align-items: center;
  h2 {
    margin: 0 12px 0 0;
    font-size: 14px;
    color: var(--color-near-black);
    font-weight: 600;
  }
  :global(.ant-btn) {
    padding: 0;
    color: var(--color-light-blue);
    font-size: 14px;
    font-weight: 600;
  }
}

.brandsCard:global(.ant-card) {
  border-radius: 6px;
  box-shadow: 0 1px 3px 0 var(--color-box-shadow);
  background-color: var(--color-white);
  padding: 12px 0px;
  :global(.ant-card-body) {
    padding: 0 4px;
  }
}

.brandItem {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  color: var(--color-light-blue);
  padding: 4px 8px 2px 8px;
  font-size: 14px;

  p {
    font-weight: 600;
    margin: 0 4px 0 0;
    svg {
      margin: 0 4px;
    }
  }

  :global(.ant-btn) {
    padding: 0;
    margin: 0 4px 0 4px;
  }
  * {
    cursor: pointer;
  }
}

.itemMarkedToDelete {
  p,
  svg {
    color: var(--color-warning);
    fill: var(--color-warning);
    text-decoration: line-through;
  }
}

.brandItemActionButtons {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 8px;
  max-width: calc(100% - 96px);
  a {
    max-width: 100%;
    button {
      max-width: 100%;
      p {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: 100%;
      }
    }
  }
  .linkButton {
    display: flex;
    align-items: center;
    span {
      margin-left: 5px;
    }
  }
}

.brandItemIcon {
  padding: 0px 8px;
}

.advancedModal {
  :global(.ant-modal-content) {
    border-radius: 8px;
    overflow: hidden;
  }
  :global(.ant-modal-body) {
    padding-top: 0px;
  }

  .modalFormItem {
    margin-top: 24px;
  }
}

.cleanBtn {
  position: absolute;
  bottom: 11px;
}

.label {
  font-size: 12px;
  font-weight: 600;
  line-height: 1.05;
  color: var(--color-near-black);
  margin-top: 10px;
}
