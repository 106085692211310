.wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 12px 12px 12px 16px;
  font-size: 10px;
  cursor: pointer;
  background-color: var(--color-white);
  border-radius: 2px;
  p {
    margin: 1px 0 0 8px;
  }
  div {
    display: flex;
    align-items: center;
  }
}
.buttonWrapper {
  display: flex;
  justify-content: space-between;
  position: relative;
  li {
    flex-grow: 1;
  }
  button {
    position: absolute;
    right: 0;
    padding: 12px;
    height: 40px;
  }
}
.icon {
  background-color: transparent;
  border: none;
  font-size: 10px;
  cursor: pointer;
  transition: var(--transition-regular);
  transform: rotate(0deg);
  outline: none;
}
.rotateIcon {
  transition: var(--transition-regular);
  transform: rotate(90deg);
}
.submenu {
  max-height: 0;
  overflow: hidden;
  transition: var(--transition-regular);
}
.activeSubmenu {
  max-height: 500px;
  transition: var(--transition-regular);
}
.wrapper:hover,
.selectedItem {
  background-color: var(--color-mid-gray);
  font-weight: bold;
}
.collapsedItem {
  justify-content: center;
  height: 40px;
}
.menuIcon {
  font-size: 20px;
  color: var(--color-dark-blue);
}
.menuText {
  font-size: 14px;
  color: var(--color-dark-blue);
}
.colapsedMenu {
  & :global(.ant-popover-inner-content) {
    padding: 0;
  }
  & :global(.ant-popover-placement-right) {
    padding-left: 22px !important;
  }
  &
    :global(.ant-popover-placement-right)
    > :global(.ant-popover-content)
    > :global(.ant-popover-arrow) {
    left: 19px !important;
  }
}
