.contentWrapper {
  overflow-x: auto;
  overflow-y: hidden;
  display: flex;
  flex: 1 1 auto;
}

.expander {
  display: block;
  min-width: 2px;
}

.subcontainerWrapper {
  position: relative;
  display: flex;
}

.floatingButton {
  position: absolute;
  background-color: var(--color-white);
  border-radius: 100%;
  right: -20px;
  top: 40%;
  height: 48px;
  width: 48px;
  border: 0;
  box-shadow: 0 4px 8px 0 var(--color-box-shadow);
  cursor: pointer;
  span,
  svg {
    color: var(--color-light-blue);
    height: 98%;
    width: 100%;
  }
}
