.wrapper {
  padding: 16px;
  height: calc(100% - 40px);
  width: 100%;
  overflow-y: auto;
}

.divider {
  margin: 45px 0px 12px 0px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

.actionButtons {
  display: flex;
  justify-content: flex-end;
}

.cancelButton:global(.ant-btn-link) {
  font-size: 18px;
  color: var(--color-dark-gray);
}

.primaryButton:global(.ant-btn-link) {
  font-size: 18px;
}

.loadingData {
  display: flex;
  height: 500px;
  align-items: center;
  justify-content: center;
}

.selectWrapper {
  position: relative;
  margin: 22px 0 0 0;
  .label {
    font-size: 14px;
    font-weight: 600;
    color: var(--color-near-black);
    margin: 0;
  }
  & :global(.ant-select) {
    width: 100%;
  }
}
