.wrapper {
  padding: 0px 16px 16px;
  height: calc(100% - 40px);
  width: 100%;
  overflow-y: auto;
  & :global(.pix-input-wrapper) {
    margin-top: 0px !important;
  }
}
.alert {
  margin-top: 16px !important;
  font-size: 12px !important;
  line-height: 1.83 !important;
  background-color: transparent !important;
  border: none !important;
  padding: 0 !important;
  & :global(.ant-alert-message) {
    margin-left: 18px;
  }
  & :global(.ant-alert-icon) {
    top: 4px;
    left: 2px;
  }
  & svg path {
    fill: var(--color-light-blue);
  }
  & b {
    color: var(--color-light-blue);
  }
}
.searchInput {
  margin-top: 16px;
}
.attributeList {
  margin-top: 8px !important;
  & :global(.ant-list-header) {
    padding: 0px;
    margin-left: -16px;
    margin-right: -16px;
  }
  .header {
    background-color: var(--color-light-gray);
    color: var(--color-dark-gray);
    display: flex;
    font-weight: bold;
    align-items: center;
    padding: 5px 16px;
    width: 100%;
  }
  .addNew {
    align-items: center;
    display: flex;
    padding: 5px 16px;
  }
  :global(.editMode) {
    svg path {
      fill: var(--color-light-blue) !important;
    }
  }
  .editIcon {
    span {
      cursor: pointer;
    }
    svg path {
      fill: var(--color-black-with-opacity);
    }
  }
}
.colValue {
  width: calc(100% - 130px);
  padding-right: 8px;
}
.colAbrev {
  width: 98px;
  text-align: right;
}
.colAction {
  width: 32px;
  text-align: center;
}
