.layout {
  height: 100%;
  header {
    align-items: center;
    background-color: white;
    display: flex;
    flex-direction: row;
    height: 48px;
    padding: 0px;
    .headerWrapper {
      background-color: white;
      display: flex;
      max-height: 48px;
      padding-left: 30px;
      width: 100%;
      z-index: 1;
    }
  }
  .darkBar {
    background-color: var(--color-background-dark);
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 208px;
    z-index: 0;
  }
  .selectBuContainer {
    align-items: center;
    display: flex;
    max-height: 28px;
    position: absolute;
    right: 163px;
    top: 11px;
  }
  .pyContainer {
    align-items: center;
    display: flex;
    max-height: 48px;
    position: absolute;
    right: 30px;
  }
}

.sider {
  background: transparent !important;
  z-index: 3;
}

.mainContent {
  display: block;
  main {
    z-index: 1;
  }
}

.headerTitle {
  align-items: center;
  color: var(--color-dark-blue);
  cursor: default;
  display: flex;
  font-size: 14px;
  font-weight: bold;
  z-index: 1;
  &::selection {
    background-color: transparent;
    color: var(--color-dark-blue);
  }
}

.loading {
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(50, 50, 93, 0.3);
  color: white;
  z-index: 9999;
  display: flex;
  align-items: center;
  justify-content: center;
}
