.heading {
  h1 {
    color: var(--color-dark-blue);
    font-weight: 600;
    font-size: 12px;
    margin: 0;
    padding: 14px 16px;
    border-bottom: solid 1px #ebedf1;
  }
}

.form {
  padding: 10px !important;
}
