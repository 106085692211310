.card:global(.ant-card) {
  border-radius: 12px;
  height: 100%;
  max-height: 100%;
  :global(.ant-card-body) {
    padding: 0px;
    height: 100%;
  }
}

.fullHeight {
  height: 85vh;
}

.loader {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 300px;
}

.cardHeading {
  padding: 8px 0 0 8px;
  font-family: "OpenSans", Arial, Helvetica, sans-serif;
  text-transform: uppercase;
  color: var(--color-dark-gray);
  font-weight: 800;
  font-size: 10px;
  margin: 0px;
}

.wordCloudHeading {
  padding: 0 0 0 8px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 22px;
  h3 {
    font-family: "OpenSans", Arial, Helvetica, sans-serif;
    font-size: 14px;
    color: var(--color-dark-blue);
    font-weight: 800;
    margin: 0;
  }
}

.wordCloudActions {
  button {
    color: var(--color-full-black);
    padding: 0px 8px;
  }
}

.divider {
  margin: 0 8px;
  border-bottom: 1px solid var(--color-light-gray);
}

.wordCloud {
  padding: 8px;
  height: calc(100% - 46px);
  max-height: calc(100% - 46px);
}

.empty {
  height: 100%;
  display: flex;
  align-items: center;
}
