.wrapper:global(.ant-modal) {
  :global(.ant-modal-header) {
    border-bottom: 1px solid var(--color-light-gray);
    :global(.ant-modal-title) {
      color: var(--color-dark-blue);
      font-weight: 600;
    }
  }
  :global(.ant-modal-body) {
    padding-top: 0;
    p {
      font-weight: 500;
      font-size: 12px;
    }
  }
  :global(.ant-modal-footer) {
    border-top: none;
  }
  .alert {
    margin: 16px 0;
  }
}
