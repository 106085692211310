.subtable {
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.gtincode {
  cursor: pointer;
  &:hover {
    color: cornflowerblue;
    text-decoration: underline;
  }
}
.detailTable {
  max-height: 100% !important;
  overflow: hidden !important;
  padding: 10px 0;
}
.iconInfoSuggestion {
  color: #ff8900 !important;
  font-size: 17px;
}

.capturesTable {
  :hover {
    cursor: pointer;
  }

  :global(.ant-table),
  :global(.ant-table-body) {
    overflow-y: auto !important;
  }
}
