.imgWrapper {
  width: 100%;
  text-align: center;
  background-color: #eee;
  padding: 10px;
}

.imgProps {
  width: 100%;
  height: auto;
  max-width: 104px;
  max-height: 104px;
}
