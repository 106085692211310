.drawer:global(.ant-drawer) {
  :global(.ant-drawer-body) {
    padding: 0;
    display: flex;
    flex-direction: column;
  }
}
.wrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex: 1 1 auto;
  padding: 0 16px;
  > div {
    margin: 0;
  }
}
.heading {
  h1 {
    color: var(--color-dark-blue);
    font-weight: 600;
    font-size: 12px;
    margin: 0;
    padding: 14px 16px;
    border-bottom: solid 1px #ebedf1;
  }
}
.buttons {
  display: flex;
  justify-content: flex-end;
  padding: 16px;
}
