.cardColumn {
  border-radius: 16px;
  overflow: hidden;
  height: calc(100vh - 70px);
  box-shadow: 0 0 32px 0 rgba(136, 152, 170, 0.15);
}

.card {
  height: 100%;
  width: 100%;
  box-shadow: 0 0 32px 0 rgba(136, 152, 170, 0.15);
  & > :global(.ant-card-body) {
    height: 100%;
    padding: 0px;
    overflow: auto;
  }
}

.dataDisplay {
  background-color: var(--color-near-white) !important;
  & > :global(.ant-card-body) {
    overflow: hidden !important;
  }
}

.borderless:global(.ant-card-bordered) {
  border: 0 !important;
}
