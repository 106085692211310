.actionItems {
  font-size: 150%;
}

.dataClusterCategory {
  color: gray;
}

.counter {
  background-color: var(--color-gray-with-opacity);
  border-radius: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--color-dark-gray);
  padding: 2px 4px 2px 6px;
  > p {
    font-size: 12px;
    margin: 0 4px 0 1px;
  }
  > span,
  svg {
    height: 16px;
    width: 16px;
  }
}

.editActions {
  margin-top: 5px;
  width: 50%;
  font-size: large;
}
