.wrapper {
  padding: 28px 16px;
  margin: 10px;
  height: calc(100% - 60px);
  overflow-y: auto;
}

.heading {
  display: flex;
  align-items: center;
  h2 {
    margin: 0 12px 0 0;
    font-size: 14px;
    color: var(--color-near-black);
    font-weight: 600;
  }
  :global(.ant-btn) {
    padding: 0;
    color: var(--color-light-blue);
    font-size: 14px;
    font-weight: 600;
  }
}

.card:global(.ant-card) {
  border-radius: 6px;
  box-shadow: 0 1px 3px 0 rgba(50, 50, 93, 0.15);
  background-color: var(--color-white);
  padding: 12px 18px;
  :global(.ant-card-body) {
    padding: 0;
  }
}

.brandItem:global(.ant-btn) {
  padding: 0;
  display: flex;
  align-items: center;
  color: var(--color-light-blue);
  font-size: 14px;
  font-weight: 600;
  p {
    margin: 0 4px 0 0;
  }
}
