.wrapperTitle {
  background-color: var(--color-white);
  display: flex;
  align-items: center;
  padding: 10px 0;
  padding-left: 16px;
  h1 {
    margin: 0;
    color: var(--color-dark-blue);
    font-weight: 600;
    font-size: 14px;
  }
}
.wrapperSubtitle {
  background-color: var(--color-light-gray);
  padding: 10px 0;
  padding-left: 16px;
  h2 {
    margin: 0;
    font-weight: 600;
    text-transform: uppercase;
    font-size: 12px;
    color: var(--color-dark-gray);
    letter-spacing: 0.9px;
  }
}
.productListing {
  display: flex;
  flex-direction: column;
  overflow: auto;
  height: calc(100% - 130px);
}
.cardWrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 16px;
  border-bottom: 1px solid var(--color-light-gray);
  .cardName {
    display: flex;
    align-items: center;
    padding-right: 4px;
  }
}
.cardTitle {
  color: var(--color-light-blue);
  text-decoration: underline;
}
.newProductFlag {
  color: var(--color-light-green);
  background-color: var(--color-light-green-with-opacity);
  padding: 4px 8px;
  border: 1px solid var(--color-light-green);
  border-radius: 2px;
  font-size: 12px;
  font-weight: 600;
}
.emptyState {
  margin-top: 24px;
}
.popoverIcon {
  margin-left: 8px;
  svg {
    height: 16px;
    width: 16px;
    color: var(--color-light-blue);
  }
}
.detailsWrapper {
  display: flex;
  flex-direction: column;
  .title {
    font-size: 10px;
    color: var(--color-dark-gray);
    margin: 0;
    margin-bottom: -2px;
  }
  .content {
    font-size: 14px;
    margin: 0;
    margin-bottom: 2px;
    color: var(--color-full-black);
  }
}
