.cardColumn {
  height: calc(100vh - 70px);
  display: flex;
  flex-direction: column;
}
:global(.ant-card-head-title),
:global(.ant-card-extra) {
  margin: 0;
  width: 100%;
  float: none;
}
:global(.ant-card-head) {
  min-height: 40px !important;
  border-bottom: 0px !important;
}
.leftSection {
  & > :global(.ant-card-body) {
    padding: 0px;
    height: 100%;
    display: flex;
    flex-direction: column;
  }
  height: 100%;
  overflow-y: hidden;
}

.switchHeaderButton {
  font-weight: 600;
  position: absolute;
  z-index: 2;
  top: -12px;
  right: 35%;
  & :global(.ant-radio-group) {
    border-radius: 4px;
    overflow: hidden;
    border: 1px solid var(--color-light-gray);
    & :global(.ant-radio-button-wrapper-checked) {
      background-color: var(--color-light-blue) !important;
    }
  }
}
