.createNavBar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: white;
}
.tabs {
  justify-content: start;
  & button {
    width: 120px;
    max-width: 120px;
  }
}
.btnExpand {
  & svg path {
    fill: var(--color-black-with-opacity);
  }
}
