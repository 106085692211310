.chartGeneral {
  border-radius: 6px !important;
  box-shadow: 0 0 32px 0 rgba(0, 0, 0, 0.16);
  height: 100%;
  max-height: 100%;
  & :global(.ant-card-body) {
    height: 100%;
    padding: 8px;
  }
}

.wrapper {
  height: 100%;
  max-height: 100%;
}

.overview {
  font-family: "OpenSans", Arial, Helvetica, sans-serif;
  text-transform: uppercase;
  color: var(--color-dark-gray);
  font-weight: 800;
  font-size: 10px;
  margin: 0px;
}

.chart {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 22px;
  h3 {
    font-family: "OpenSans", Arial, Helvetica, sans-serif;
    font-size: 14px;
    color: var(--color-dark-blue);
    font-weight: 800;
    margin: 0;
  }
  button {
    color: var(--color-black-with-opacity);
  }
}

.divider {
  margin-bottom: 8px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

.loader {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 400px;
}

.alignCenter {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.chartContent {
  height: calc(100% - 46px);
}
