.whiteBackgroundFix {
  display: flex;
  flex-direction: column;
  background-color: var(--color-white);
}

.expandedCard {
  display: flex;
  flex-direction: column;
  height: 100%;
  background-color: var(--color-light-blue-with-opacity);
}

.cardWrapper {
  position: relative;
  display: flex;
  align-items: center;
  padding: 8px 0 12px 0;
  margin: 0 16px;
  border-bottom: 1px solid var(--color-light-gray);
}

.picture {
  border: 1px solid var(--color-light-gray);
  background-color: white;
  margin-right: 8px;
  min-height: 105px;
  min-width: 105px;
  max-height: 105px;
  max-width: 105px;
  cursor: pointer;
}

.featuresWrapper {
  flex: 1 1 auto;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
}

.subtitleWrapper {
  display: flex;
  font-size: 12px;
}

.subtitle {
  color: var(--color-light-blue);
  margin: 0;
  margin-right: 4px;
  font-weight: 600;
}

.subtitleContent {
  text-transform: uppercase;
  margin: 0;
}

.detailsButtonContainer {
  display: flex;
  gap: 16px;
  align-items: center;
}

.detailsButton:global(.ant-btn) {
  background-color: transparent;
  color: var(--color-light-blue);
  border-color: var(--color-light-blue);
  display: flex;
  align-items: center;
  justify-content: center;
  span,
  svg {
    height: 20px;
    width: 20px;
  }
  p {
    margin-bottom: 0px;
    margin-left: 8px;
    margin-right: 8px;
    white-space: normal;
    line-height: 12px;
  }
  font-size: 12px;
}

.productImage,
.hoveringImage {
  min-height: 105px;
  min-width: 105px;
  max-height: 105px;
  max-width: 105px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.hoveringImage {
  position: relative;
  z-index: 10;
  margin-top: -105px;
  background-color: rgba($color: #000000, $alpha: 0.7);
  & svg {
    width: 25px;
    height: 25px;
  }
  & svg path {
    fill: white;
  }
}
