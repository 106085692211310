.baseButton {
  position: relative;
  font-size: 12px;
  padding: 0px 20px;
  border: 2px solid transparent;
  background-color: var(--color-white);
  cursor: pointer;
  font-weight: 400;
  &:focus {
    outline: none;
  }
}

.selectedItem {
  color: var(--color-dark-blue);
  border-bottom: 2px solid var(--color-dark-blue);
}

.availableItem {
  color: var(--color-dark-gray);
}

.arrowedItem {
  padding: 15px;
  padding-left: 40px;
  padding-right: 40px;
  height: 48px;
  text-transform: uppercase;
  color: var(--color-white);
  background-color: var(--color-light-blue);
  position: relative;
  border-bottom: 0;
}

.arrowedSelectedItem {
  font-weight: 600;
  background-color: var(--color-orange);
}

.arrow {
  position: absolute;
  top: -2px;
  right: -26px;
  z-index: 1;
  height: 48px;
}
