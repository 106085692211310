.extraSection {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0px 16px 0px 0px !important;
  .productHeader {
    display: flex;
    align-items: center;
    min-width: 25%;
  }
  .searchInput {
    margin-left: 10px;
  }
  .cardButtons {
    margin-left: 5px;
    padding: 0px !important;
    width: 36px;
  }
  .filterIndicator {
    background-color: var(--color-warning);
    border-radius: 100%;
    font-size: 10px;
    font-weight: 600;
    color: var(--color-white);
    position: absolute;
    height: 15px;
    width: 15px;
    right: -8px;
    top: -5px;
    z-index: 2;
  }
}
