.wrapper {
  padding: 0px 16px 16px;
  width: 100%;
  overflow-y: auto;
  & :global(.pix-action-button) {
    border-color: var(--color-light-blue) !important;
    color: var(--color-light-blue) !important;
    width: 100%;
    margin-top: 16px;
    &:hover {
      background-color: var(--color-light-blue-opacity) !important;
    }
  }
}
.actionButtons {
  display: flex;
  justify-content: flex-end;
}
.primaryButton:global(.ant-btn-link) {
  font-size: 18px;
}
.divider {
  margin: 20px 0px 12px 0px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}
.alert {
  margin-top: 8px !important;
}

.label {
  font-size: 12px;
  font-weight: 600;
  line-height: 1.05;
  color: var(--color-near-black);
}

.taxonomiesWrapper {
  margin: 10px 0;
}

.taxonomyItem {
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  direction: rtl;
  font-size: 15px !important;
}
