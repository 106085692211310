.subBrandsCard:global(.ant-card) {
  border-radius: 6px;
  box-shadow: 0 1px 3px 0 var(--color-box-shadow);
  background-color: var(--color-white);
  padding: 12px 0px;
  :global(.ant-card-body) {
    padding: 0 4px;
  }
}

.subBrandItem {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  color: var(--color-light-blue);
  padding: 4px 8px 2px 8px;
  font-size: 14px;

  p {
    font-weight: 600;
    margin: 0 4px 0 0;
    svg {
      margin: 0 4px;
    }
  }

  :global(.ant-btn) {
    padding: 0;
    margin: 0 4px 0 4px;
  }
  * {
    cursor: pointer;
  }
}

.heading {
  display: flex;
  align-items: center;
  h2 {
    margin: 0 12px 0 0;
    font-size: 14px;
    color: var(--color-near-black);
    font-weight: 600;
  }
  :global(.ant-btn) {
    padding: 0;
    color: var(--color-light-blue);
    font-size: 14px;
    font-weight: 600;
  }
}

.subBrandItemIcon {
  padding: 0px 8px;
}

.label {
  font-size: 12px;
  font-weight: 600;
  line-height: 1.05;
  color: var(--color-near-black);
  margin-top: 10px;
  padding-left: 10px;
}
