.wrapper {
  position: relative;
  display: flex;
  align-items: center;
  padding: 8px;
  font-size: 12px;
  flex-grow: 1;
  p {
    margin: 0;
    color: var(--color-reading-black);
  }
  h4 {
    font-size: 10px;
    margin: 0;
    color: var(--color-dark-gray);
    line-height: 10px;
  }
  border-bottom: 1px solid var(--color-light-gray);
}

.backgroundLight {
  background-color: var(--color-background-light);
}
.backgroundLightBlue {
  background-color: #98b6e2;
}

.backgroundGreen {
  background-color: #98e2b7;
}

.descriptionTitle {
  font-size: 10px;
  font-weight: bold;
  color: var(--color-dark-gray);
}

.brandDescription {
  font-weight: 400;
  text-transform: uppercase;
}

.infoWrapper {
  width: 100%;
  padding: 0 15px;
  display: block;
  justify-content: space-between;
  align-items: center;
  > div {
    max-width: 150px;
    flex: 1 1 auto;
  }
  svg {
    color: var(--color-light-blue);
    width: 20px;
    height: 20px;
  }
}

.picture {
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid var(--color-light-gray);
  background-color: white;
  margin-right: 8px;
  min-height: 105px;
  min-width: 105px;
  max-height: 105px;
  max-width: 105px;
}
.suggestionType {
  margin-bottom: 4px;
}
