.createNavBar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: white;
}
.tabs {
  justify-content: start;
  & button {
    width: 100px;
    max-width: 100px;
  }
}
.btnExpand {
  & svg path {
    fill: var(--color-black-with-opacity);
  }
}
.secondaryButton {
  height: auto !important;
  color: var(--color-black-with-opacity) !important;
}
.advOptions {
  border: 0px !important;
  background-color: transparent !important;
  width: 14px !important;
}

.maxHeight40 {
  max-height: 40px;
}
.filterIndicator {
  background-color: var(--color-warning);
  border-radius: 100%;
  font-size: 10px;
  font-weight: 600;
  color: var(--color-white);
  position: absolute;
  height: 15px;
  width: 15px;
  right: 6px;
  top: 6px;
  z-index: 2;
}
