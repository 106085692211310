.attributeLabel {
  font-weight: bold;
  font-size: 60%;
}

.attributeWrapper {
  border: 1px solid #ccc;
  padding: 3px;
  margin: 9px;
  border-radius: 5px;
  background-color: #eee;
}
