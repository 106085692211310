.modalHeader {
  display: flex;
  align-items: center;
  svg {
    fill: var(--color-yellow);
    height: 20px;
    width: 20px;
    transform: rotate(180deg);
  }
  h1 {
    margin: 0;
    margin-left: 8px;
    font-size: 16px;
    font-weight: 600;
  }
}
.modalBody:global(.ant-modal) {
  :global(.ant-modal-content) {
    :global(.ant-modal-body) {
      padding: 0;
    }
  }
}
.modalAlert {
  padding: 16px 24px;
  background-color: var(--color-background-light);
  display: flex;
  align-items: center;
  svg {
    height: 16px;
    width: 16px;
    color: var(--color-light-blue);
  }
  p {
    margin: 0;
    margin-left: 8px;
    font-size: 10px;
  }
}
.modalContent {
  padding: 8px 24px;
}
.modalTextContent {
  color: var(--color-reading-black);
  font-size: 14px;
  margin: 10px 0 0 0;
  line-height: 22px;
}
.itemDescription {
  color: var(--color-light-blue);
  font-size: 14px;
  margin: 0;
  margin-bottom: 8px;
  text-transform: uppercase;
  line-height: 22px;
  font-weight: 600;
}
.yandehProduct {
  width: calc(100% + 48px);
  background-color: rgba(97, 156, 248, 0.08);
  margin-left: -24px;
  padding: 12px 12px;
  display: flex;
  flex-direction: column;
}
.cardContent {
  padding: 8px;
  display: flex;
  flex-direction: column;
  .cardTitle {
    font-size: 12px;
    color: var(--color-dark-gray);
  }
  .cardValue {
    font-size: 13px;
    font-weight: 600;
    color: var(--color-black-with-opacity);
    margin-left: 8px;
  }
}
.modalSelect {
  margin-top: 0px;
}
.modalDivider {
  margin: 12px 0 !important;
}

.searchSelectWrapper {
  margin-top: 20px !important;
}

.packingTableContainer {
  padding-top: 15px;
}
