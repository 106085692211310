.pixImage {
  padding: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.imageIcon svg {
  height: 40px;
  width: 40px;
}
