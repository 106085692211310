.dropdown {
  min-width: 150px !important;
}

.menuItem {
  padding: 0 !important;
  button {
    display: flex;
    align-items: center;
    padding: 8px;
    border: 0;
    cursor: pointer;
    &:hover {
      background-color: var(--color-light-gray);
    }
    svg {
      width: 16px;
      height: 16px;
      margin: 0 8px;
    }
  }
}
