.wrapper {
  padding: 8px;
}

.charts {
  height: calc(100% - 40px);
  .chart {
    padding: 8px 8px 0px 8px;
  }
}

.wordCloud {
  padding-bottom: 8px !important;
}

.fullHeight {
  height: 100%;
  max-height: 100%;
  padding-bottom: 8px !important;
}

.regularHeight {
  height: 50%;
  max-height: 50%;
}

.hide {
  display: none;
}

.empty {
  height: 100%;
  width: 100%;
  margin-top: 42px;
}
