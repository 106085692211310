.wrapper {
  padding: 0px 16px 16px;
  height: calc(100% - 40px);
  width: 100%;
  overflow-y: auto;
  & :global(.pix-input-wrapper) {
    margin-top: 0px !important;
  }
}
.alert {
  margin-top: 16px !important;
  font-size: 12px !important;
  line-height: 1.83 !important;
  background-color: transparent !important;
  border: none !important;
  padding: 0 !important;
  & :global(.ant-alert-message) {
    margin-left: 18px;
  }
  & :global(.ant-alert-icon) {
    top: 4px;
    left: 2px;
  }
  & svg path {
    fill: var(--color-light-blue);
  }
  & b {
    color: var(--color-light-blue);
  }
}
.searchInput {
  margin-top: 16px;
}
