.modalHeader {
  display: flex;
  padding: 8px 16px;
  background-color: var(--color-light-gray);
  font-size: 12px;
  color: var(--color-dark-blue);
  p {
    margin: 4px 0;
  }
  h2 {
    color: var(--color-dark-gray);
    font-weight: 600;
    font-size: 16px;
    margin: 0 0 4px 0;
  }
}

.alertIcon {
  margin-right: 16px;
}

.removeButton {
  color: var(--color-warning);
}

.productName {
  font-weight: 600;
}

.customModalStyle {
  :global(.ant-modal-body) {
    padding: 0;
  }
}

.tagTotalItems {
  background-color: rgb(50, 50, 93);
  color: white;
  width: 40px;
  height: 20px;
  border-radius: 10px;
  font-weight: bold;
  display: flex;
  justify-content: center;
  align-items: center;
}

.modalTable {
  & :global(.ant-table-body) {
    max-height: 200px !important;
  }
}
