.modal {
  & :global(.ant-modal-body) {
    padding: 12px 24px;
  }
  .select {
    margin-top: 0px;
  }
}
.inputWrapper {
  width: 100%;
  height: 100%;
  & :global(.pix-multiple-paste-input) {
    margin-top: 0px;
  }
}
.inputTitle {
  margin: 0;
  font-weight: 600;
  color: var(--color-near-black);
}
.smallTitle {
  font-size: 12px;
  font-weight: 400;
}
.multiselect {
  width: 100%;
}
