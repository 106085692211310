.colDragger {
  min-height: 150px;
  max-height: 256px;
  margin: 10px;
}

.excelContainer {
  background-color: #27ae60;
  position: relative;
  margin: 15px;
  min-height: 150px !important;
  max-height: 256px !important;
}
.excelImage {
  height: 100%;
  width: 200px !important;
  position: absolute !important;
  right: 0px;
  & :global(.ant-image-img) {
    height: 100%;
  }
}
.excelMessage {
  z-index: 333;
  position: absolute !important;
  padding: 0px !important;
  width: 98%;
  top: 25%;
  left: 0px;
  text-align: center;
}
.excelIcon {
  color: white !important;
  font-size: 30px;
}
.downloadExcel {
  font-size: 15px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: center;
  color: #ffffff;
}
.btnExcelDownload {
  margin-top: 60px;
  background: white !important;
  background-color: white !important;
  color: #27ae60 !important;
  font-weight: bold !important;
  cursor: pointer !important;
  border-color: #27ae60 !important;
  padding: 8px 12px;
  border: 1px solid #27ae60;
}

.colBtnExcel {
  margin-top: 10px;
}

.fileDoneIcon {
  font-size: 16px;
  color: #8898aa !important;
  margin-right: 10px;
  cursor: pointer;
}

.selectorWrapper {
  margin-left: 30px;
  margin-top: -15px;
}

.labelSelector {
  margin-left: 31px;
  font-weight: 600;
  color: #32325d;
}

.selectorContainer {
  margin-top: 10px;
}

.uploadSelector {
  width: 50%;
  margin: 10px 0 !important;
}
