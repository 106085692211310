.divider {
  margin: 45px 0px 12px 0px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

.actionButtons {
  display: flex;
  justify-content: flex-end;
}

.cancelButton:global(.ant-btn-link) {
  font-size: 18px;
  color: #a7a7a7;
}

.primaryButton:global(.ant-btn-link) {
  font-size: 18px;
}

:global(.deleteButton) {
  &:global(.ant-btn-link) {
    font-size: 18px;
    color: red;
  }
  &:global(.ant-btn-link):hover {
    font-size: 18px;
    color: pink;
  }
}
:global(.activateButton) {
  &:global(.ant-btn-link) {
    font-size: 18px;
    color: green;
  }
  &:global(.ant-btn-link):hover {
    font-size: 18px;
    color: lightgreen;
  }
}

.loadingData {
  display: flex;
  height: 500px;
  align-items: center;
  justify-content: center;
}

.selectWrapper {
  position: relative;
  margin: 22px 0 0 0;
  .label {
    font-size: 14px;
    font-weight: 600;
    color: #525f7f;
    margin: 0;
  }
  & :global(.ant-select) {
    width: 100%;
  }
}
.link {
  margin-left: 10px;
  font-size: 12px;
}

.sanitationSelect {
  p {
    padding-right: 5px;
    font-size: 13px;
    font-weight: 600;
    line-height: 1.05;
    color: "var(--color-near-black)";
    margin: 0;
  }
  span {
    font-size: 15px;
  }
}
