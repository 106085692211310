.card {
  height: 95%;
  width: 100%;
  & > :global(.ant-card-body) {
    height: 100%;
    padding: 0px;
    overflow-y: auto;
  }
  & :global(.ant-pagination) {
    margin-right: 10px !important;
  }
}

.table {
  :global(.ant-table-cell) {
    font-size: 12px;
    padding: 8px;
  }
  :global(.ant-table-thead) th {
    padding: 0px 4px !important;
  }
  :global(.ant-table) {
    thead {
      & > tr > th {
        font-size: 10px;
        background-color: var(--color-background-light);
        color: var(--color-dark-gray);
        font-weight: 600;
        text-transform: uppercase;
        letter-spacing: 0.9px;
        padding: 8px;
      }
    }
  }
  :global(.ant-table-column-sorter-inner) {
    display: flex;
    flex-direction: column;
    height: 24px;
    justify-content: space-between;
  }
  :global(.ant-table-column-sorters) {
    padding: 0px 8px !important;
  }
  :global(.ant-table-column-sorter-up),
  :global(.ant-table-column-sorter-down) {
    font-size: 12px;
  }
  :global(.ant-table-selection-col) {
    width: 40px !important;
    min-width: 40px !important;
  }
  :global(.ant-table-expand-icon-col) {
    width: 30px !important;
  }
  :global(.react-resizable) {
    position: relative;
    background-clip: padding-box;
  }
  :global(.react-resizable-handle) {
    background-color: gainsboro;
    position: absolute;
    width: 1px;
    height: calc(100% - 8px);
    bottom: 4px;
    right: -1px;
    cursor: col-resize;
    z-index: 1;
  }
  :global(.ant-table-row-expand-icon) {
    margin-left: 25%;
  }
}
