.cardWrapper {
  margin-bottom: 4px;
}

.wrapper {
  position: relative;
  display: flex;
  align-items: center;
  padding: 8px;
  font-size: 12px;
  background-color: var(--color-background-light);
  flex-grow: 1;
  p {
    margin: 0;
    color: var(--color-dark-blue);
  }
  h4 {
    font-size: 10px;
    margin: 0;
    color: var(--color-dark-gray);
    line-height: 10px;
  }
}

.productDescription {
  font-weight: 600;
}

.productPicture {
  margin-right: 12px;
  img {
    width: 100%;
    width: 50px;
    height: 50px;
  }
}

.infoWrapper {
  min-height: 40px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

.subInfoWrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.collapseArrow {
  background: transparent;
  border: none;
  position: absolute;
  right: 8px;
  top: 8px;
  cursor: pointer;
  transition: transform 0.5s ease-in-out;
}

.rotateArrow {
  transform: rotate(-180deg);
  transition: transform 0.5s ease-in-out;
}

.detailsWrapper {
  height: 0px;
  overflow: hidden;
  transition: height 0.25s ease-in-out;
}

.isExpanded {
  height: 100%;
  transition: height 0.25s ease-in-out;
}

.customCollapse {
  border: none !important;
  background-color: var(--color-background-light) !important;
  & > :global(.ant-collapse-content) > :global(.ant-collapse-content-box) {
    padding: 0px;
  }
  & > :global(.ant-collapse-header) {
    text-align: center;
    padding: 4px !important;
    font-size: 12px;
    font-weight: 600;
  }
}

.overflowAuto {
  overflow: auto;
  scrollbar-width: thin;
}
