.taxonomiesWrapper {
  margin: 10px 0;
}

.taxonomyItem {
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  direction: rtl;
  font-size: 15px !important;
}

.helpMessageIcon {
  margin-left: 10px;
  margin-top: 10px;
}
