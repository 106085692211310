.rowContainer {
  padding: 10px !important;
}
.statusContainer {
  border-radius: 8px;
  background-color: #f6f9fc;
  display: inline-flex !important;
  height: 76px !important;
  padding: 14px !important;
}
.dividerHorizontal {
  height: 100% !important;
  margin: 0px 20px !important;
}
.marginHorizontal1p {
  margin: 0px 1%;
}
.borderRadius20px {
  border-radius: 20px !important;
}
.btExportErrors {
  position: absolute !important;
  right: 30px;
  top: 21px;
  color: #8898aa !important;
  border: 1px solid #ebedf1 !important;
  border-radius: 16px !important;
  background-color: #ebedf1 !important;
  &:hover {
    color: #748291 !important;
    border: 1px solid #d1d4db !important;
  }
}
.btnLabel {
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  text-align: left;
  color: #8898aa;
  margin-left: 4px;
  &:hover {
    color: #748291 !important;
  }
}
.pagination {
  text-align: right;
  background-color: white;
}
.loadMoreBtn {
  bottom: 0px;
  position: relative;
  width: 100%;
}
.marginVertical15 {
  margin: 15px 0px;
}

.badgeRedIcon {
  background-color: rgba(235, 0, 16, 0.7);
  border-radius: 10px;
  padding: 2px;
  & svg path {
    fill: white;
  }
}

.badgeGreenIcon {
  background-color: rgba(39, 174, 96, 0.9);
  border-radius: 10px;
  padding: 2px;
  & svg path {
    fill: white;
  }
}

.btnRemove {
  border-radius: 2px !important;
  background-color: #ebedf1 !important;
  color: #8898aa !important;
  margin-right: 11px !important;
}
.margin0 {
  margin: 0px !important;
}

.headerError {
  color: #eb0024 !important;
  margin-left: 5px;
}
.headerErrorIcon {
  max-width: 16px;
  max-height: 16px;
  background-color: #eb0024;
  border-radius: 10px;
  padding: 3px;
  & svg path {
    fill: white;
  }
}

.table {
  :global(.ant-table-cell) {
    font-size: 12px;
    padding: 8px;
  }
  :global(.ant-table-thead) tr {
    height: 48px !important;
  }
  :global(.ant-table-body) {
    overflow-y: auto !important;
    max-height: calc(100vh - 280px) !important;
  }
}
.infinityTable {
  height: auto !important;
}
