.wrapper {
  align-items: center;
  background-color: var(--color-light-gray);
  cursor: pointer;
  display: flex;
  font-size: 10px;
  justify-content: space-between;
  padding: 12px 12px 12px 40px;
  p {
    margin: 0;
    font-size: 12px;
  }
}

.wrapper:hover,
.selectedItem {
  background-color: var(--color-mid-gray);
  font-weight: bold;
}
