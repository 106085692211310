.detailsWrapper {
  display: flex;
  flex-direction: column;
}

.title {
  font-size: 10px;
  color: var(--color-dark-gray);
  margin: 0;
  margin-bottom: -2px;
}

.content {
  font-size: 14px;
  margin: 0;
  margin-bottom: 2px;
  color: var(--color-full-black);
}

.popoverIcon {
  position: absolute;
  right: -10px;
  top: 10px;
  svg {
    height: 20px;
    width: 20px;
    color: var(--color-light-blue);
  }
}

.openPopover:global(.ant-popover) {
  :global(.ant-popover-content) {
    :global(.ant-popover-title) {
      font-size: 16px;
      font-weight: 800;
      padding: 4px 0px 4px 8px;
    }
    :global(.ant-popover-inner-content) {
      padding: 8px 0px 2px 8px;
    }
  }
}
