.itemWrapper {
  display: flex;
  justify-content: space-between;
  align-items: stretch;
  background-color: var(--color-background-light);
  padding: 8px 12px;
  min-height: 70px;
  border-bottom: 1px solid var(--color-light-gray);
  cursor: pointer;
}

.selectedItem {
  * {
    color: var(--color-white) !important;
  }
}

.thinWrapper {
  min-height: 50px;
  max-height: 50px;
}

.itemData {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.centralizeContent {
  justify-content: center;
  > p {
    font-weight: 500;
  }
}

.title {
  color: var(--color-reading-black);
  font-weight: 600;
  margin: 0;
  font-size: 14px;
  word-break: break-word;
}

.detailedSubtitleTitle {
  font-size: 10px;
  color: var(--color-mid-gray);
  margin: 0;
  margin-bottom: -4px;
}

.detailedSubtitleValue {
  font-size: 12px;
  color: var(--color-reading-black);
  font-weight: 600;
  margin: 0;
}

.iconisedSubtitleWrapper {
  display: flex;
  align-items: center;
  text-transform: capitalize;
  svg {
    height: 24px;
    width: 24px;
  }
  p {
    margin: 0;
    font-size: 12px;
    word-break: break-word;
  }
}

.counter {
  background-color: var(--color-gray-with-opacity);
  border-radius: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--color-dark-gray);
  padding: 2px 4px 2px 6px;
  > p {
    font-size: 12px;
    margin: 0 4px 0 1px;
  }
  > span,
  svg {
    height: 16px;
    width: 16px;
  }
}

.buttonsWrapper {
  margin-top: 4px;
  color: var(--color-dark-gray);
  display: flex;
  justify-content: flex-end;
  :first-child {
    padding: 0;
  }
  > button {
    border: 0;
    background-color: transparent;
    cursor: pointer;
    height: 32px;
    width: 24px;
    > span,
    svg {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 100%;
      width: 100%;
    }
  }
}

.arrowButton {
  padding: 0 5px;
}

.option {
  margin: -5px -4px -5px -12px !important;
  color: var(--color-dark-gray) !important;
  p {
    display: inline;
    margin: 0;
    margin-left: 8px;
  }
}
