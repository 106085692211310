.inputWrapper {
  margin-bottom: 8px;
}
.smallerInputGroup {
  display: flex;
  justify-content: space-between;
  div {
    width: 48%;
  }
}
.modalTitle {
  color: var(--color-dark-blue);
}
.select {
  width: 100% !important;
  margin-top: 0;
  & :global(.ant-select-selector) {
    margin: 0px;
  }
}
.cleanBtn {
  position: absolute;
  bottom: 11px;
}
