.cardColumn {
  border-radius: 16px;
  overflow: hidden;
  height: calc(100vh - 70px);
  box-shadow: 0 0 32px 0 rgba(136, 152, 170, 0.15);
}

.card {
  height: 100%;
  width: 100%;
  overflow: auto;
  box-shadow: 0 0 32px 0 rgba(136, 152, 170, 0.15);
  & :global(.ant-card-head) {
    padding: 0px;
  }
  & :global(.ant-card-head-title) {
    padding: 0px !important;
  }
  & > :global(.ant-card-body) {
    height: 100%;
    padding: 0px;
  }
}

.cardList {
  height: 100%;
  width: 100%;
  overflow: auto;
  box-shadow: 0 0 32px 0 rgba(136, 152, 170, 0.15);
  & > :global(.ant-card-body) {
    padding: 0px;
    height: calc(100vh - 190px);
  }
}

:global(.ant-card-head-title),
:global(.ant-card-extra) {
  padding: 4px 0px !important;
}

:global(.ant-card-head) {
  min-height: 40px !important;
  border-bottom: 0px !important;
  background: var(--color-white) !important;
}

.header {
  display: flex;
}

.dataDisplay {
  background-color: var(--color-near-white) !important;
}

.title {
  font-size: 12px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  h2 {
    margin: 0;
    font-weight: 600;
    font-size: 12px;
    color: var(--color-dark-blue);
  }
}

.action {
  font-size: 12px !important;
  float: right;
}

.table {
  :hover {
    cursor: pointer;
  }

  :global(.ant-table),
  :global(.ant-table-body) {
    overflow-y: auto !important;
  }
}
.actionButtons {
  & :global(.pix-action-button) {
    border-color: var(--color-light-blue) !important;
    color: var(--color-light-blue) !important;
    &:hover {
      background-color: var(--color-light-blue-opacity) !important;
    }
  }
}

.filterIndicator {
  background-color: var(--color-warning);
  border-radius: 100%;
  font-size: 9px;
  font-weight: 600;
  color: var(--color-white);
  position: absolute;
  height: 12px;
  width: 12px;
  right: 8px;
  top: 8px;
  z-index: 2;
}
