.cardColumn {
  border-radius: 16px;
  overflow: hidden;
  height: calc(100vh - 70px);
  box-shadow: 0 0 32px 0 rgba(136, 152, 170, 0.15);
}

.card {
  height: 100%;
  width: 100%;
  box-shadow: 0 0 32px 0 rgba(136, 152, 170, 0.15);
  background-color: white;
  & > :global(.ant-card-body) {
    height: 100%;
    padding: 0px;
    overflow: auto;
  }
}

.title {
  background-color: black;
  color: white;
  width: 100%;
  text-align: center;
  font-size: 40%;
  border-radius: 5px 5px 0 0;
  padding: 10px 0;
}

.searchInput {
  margin: 0 10px;
}

.headerSearch {
  font-size: 45%;
  height: 40px !important;
}

.listItems {
  height: calc(100% - 150px);
  overflow-y: auto;
}
