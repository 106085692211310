.nav {
  display: flex;
  font-size: 12px;
  padding: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  background-color: white;
}

.icons {
  display: flex;
  position: absolute;
  right: 0;
  margin-top: 8px;
}

.btnNav {
  border: none !important;
  height: auto !important;
}

.btnClearFilters {
  margin-top: 5px;
}

.deletedSelect {
  height: 30px;
}
