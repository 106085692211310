.cardColumn {
  border-radius: 16px;
  overflow: hidden;
  height: calc(100vh - 70px);
  box-shadow: 0 0 32px 0 var(--color-box-shadow);
}

.cardList {
  height: 100%;
  width: 100%;
  overflow: auto;
  box-shadow: 0 0 32px 0 rgba(136, 152, 170, 0.15);
  & > :global(.ant-card-body) {
    padding: 0px;
    overflow-x: hidden;
  }
}

.card {
  height: 100%;
  width: 100%;
  & > :global(.ant-card-body) {
    padding: 0px;
    height: 100%;
  }
  box-shadow: 0 0 32px 0 var(--color-box-shadow);
  & > :global(.ant-card-head) {
    border-bottom: 2px solid var(--color-light-gray) !important;
  }
  & :global(.ant-card-head) {
    padding: 10px 24px;
  }
  & :global(.ant-row-center) {
    margin: 0px !important;
  }
}
.title {
  font-size: 12px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  h2 {
    margin: 0;
    font-weight: 600;
    font-size: 12px;
    color: var(--color-dark-blue);
  }
}
