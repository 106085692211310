.card {
  background-color: white;
  height: 100%;
  width: 100%;
  & > :global(.ant-card-body) {
    padding: 0px;
    height: 100%;
  }
  box-shadow: 0 0 32px 0 var(--color-box-shadow);
  & > :global(.ant-card-head) {
    border-bottom: 2px solid var(--color-light-gray) !important;
  }
  & :global(.ant-card-head) {
    padding: 10px 24px;
  }
  & :global(.ant-row-center) {
    margin: 0px !important;
  }
}
