.search {
  display: flex;
  width: 75%;
  align-items: center;
  padding: 8px 18px;
  font-size: 14px;
  p {
    margin-bottom: 0;
    margin-right: 5px;
  }
  .searchInput {
    max-height: 32px;
    width: 100%;
    border-radius: 24px !important;
  }
  .pills {
    display: flex;
    align-items: center;
    padding: 5px;

    .pill {
      border-radius: 12px !important;
      font-weight: bold;
      text-align: center;
    }
  }
  .clearButton {
    & svg {
      fill: var(--color-black-with-opacity);
    }
  }
  .termRows {
    overflow-x: auto;
    padding: 5px;
  }
  .helpTermPopover {
    padding-top: 10px;
    padding-left: 10px;
  }
}

.helpTermExample {
  color: var(--color-light-blue);
  font-weight: bold;
}
