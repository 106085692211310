.wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0px;
  background-color: var(--color-white);
  overflow: hidden;
  width: 100%;
  height: 100%;
  transition: var(--transition-regular);
  footer {
    bottom: 0;
    color: var(--color-dark-blue);
    font-size: 12px;
    font-weight: bold;
    padding-left: 20px;
    position: absolute;
    width: 100%;
  }
}

.collapsed {
  transition: var(--transition-regular);
  width: 62px;
}

.itemList {
  padding: 0px;
  margin-bottom: 0;
  width: 100%;
}

.menuHeader {
  width: 100%;
  padding: 15px 8px 15px 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: var(--color-white);
  button {
    font-size: 16px;
    background-color: transparent;
    border: none;
    cursor: pointer;
  }
}

.collapsedHeader {
  justify-content: center;
}

.search {
  display: flex;
  padding: 8px;
  & :global(.ant-input-affix-wrapper) {
    border-radius: 24px;
  }
}

.expandButton {
  display: flex;
  outline: none;
  & path {
    stroke: black;
    stroke-width: 15px;
  }
}
