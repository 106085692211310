.header {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--color-light-blue);
  padding: 12px 10px;
  margin-bottom: 20px;
  h2 {
    font-weight: 800;
    font-size: 12px;
    text-transform: uppercase;
    margin: 0;
    color: var(--color-white);
    fill: var(--color-white);
  }
}
