.pixSelect {
  width: 100%;
}

.loadMore {
  width: 100%;
  display: flex;
  padding: 8px;
  justify-content: center;
  cursor: pointer;
}
