.subTitle {
  text-align: center;
  font-weight: bold;
}

.img {
  border-radius: 10px !important;
  margin-bottom: 5px !important;

  // Fix issue with the border radius
  overflow: hidden;
}

.center {
  text-align: center;
}
