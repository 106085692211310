.loadMoreData {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 8px;
  font-size: 15px;
  .allDataLoaded {
    color: var(--color-light-gray);
  }
}
