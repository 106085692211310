.valueInputs {
  display: flex;
  align-items: baseline;
  & :global(.pix-input-wrapper) {
    width: calc(100% - 30px);
  }
  & svg path {
    fill: var(--color-black-with-opacity);
  }
}
